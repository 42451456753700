@import (reference) '../../theme.less';

.layout {
  .sider {
    box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.02), inset -2px 0 0 0 #ecedf2;
    background-color: @heading-color;

    .trigger {
      padding: 30px;
      font-size: 16px;
    }
  }

  .content {
    background-color: @primary-color-light;
    min-height: calc(~'100vh - 64px');
  }

  .nav {
    .ant-menu-item {
      width: calc(100% - 1px);
      height: 60px;
      margin-top: 0;
      margin-bottom: 0 !important;
      padding-top: 12px !important;
      padding-bottom: 12px !important;

      &::after {
        border-color: @primary-color;
        right: auto;
        left: 0;
      }

      .nav-title {
        font-size: 16px;
        font-weight: 500;
        color: @text-primary-color;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        font-family: @font-family;
        color: @text-primary-color;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        vertical-align: middle;
      }

      .anticon {
        font-size: 16px;
        vertical-align: middle;
      }
    }

    .ant-menu-item.ant-menu-item-selected {
      background-color: @bg-color-light;

      .icon svg {
        fill: @primary-color;
      }

      &.ant-menu-item::after {
        border-color: @primary-color;
        transform: none;
        opacity: 1;
        right: auto;
        left: 0;
      }
    }

    &.ant-menu-inline .ant-menu-item::after {
      right: auto;
      left: 0;
    }
  }

  .header {
    background-color: @heading-color;
    padding-left: 0;
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    position: relative;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .shapes {
        width: 100%;
        top: 0;
        position: absolute;

        .shape-1 {
          width: 0;
          height: 0;
          position: absolute;
          top: 0;

          &::before {
            content: '';
            position: absolute;
            left: -62px;
            top: 0;
            width: 0;
            height: 0;
            border: 18px solid transparent;
            border-top-color: @primary-color;
            opacity: 0.5;
            z-index: 4;
          }

          &::after {
            content: '';
            position: absolute;
            left: -62px;
            top: 0;
            width: 0;
            height: 0;
            border: 62px solid transparent;
            border-top-color: #fff;
            z-index: 3;
          }
        }
      }
    }

    //@media screen and (max-width: @screen-sm) {
    //  padding-left: 5px;
    //}
    .btn-default.inactive {
      opacity: 0.5;
      pointer-events: none;
    }

    &::after {
      // shadow under header
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: transparent;
      box-shadow: 0 5px 15px 0 black;
      z-index: 30;
    }

    .header-first-section {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: @screen-md) {
        flex-basis: 100%;
      }

      .icon.trigger {
        width: 80px; // sider width

        @media screen and (max-width: @screen-sm) {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: @primary-color;

          > svg {
            fill: #fff;
          }
        }
      }

      .header-search-area {
        padding-right: calc(25px - 12px);
      }
    }

    .header-second-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      border-top: none;
      border-bottom: none;
      //padding-left: 25px;
      padding-right: 25px;
      z-index: 2; // call-center tabs over

      @media screen and (max-width: @screen-md) {
        padding-left: 7px;
        padding-right: 7px;
        border: none;
      }

      .header__user-name {
        font-family: @font-family;
        font-size: @font-size-base;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: @text-accent-color;
        margin-right: 10px;
        margin-left: 10px;

        @media screen and (max-width: @screen-md) {
          display: none;
        }
      }

      .icon img {
        padding: 4px 0;
      }
    }

    .header-last-section:not(:empty) {
      padding-left: 15px;
      padding-right: 10px;
      border-left: 2px solid @grey-box-shadow;

      @media screen and (max-width: @screen-sm) {
        padding-left: 5px;
        padding-right: 0;
      }
    }

    .company-bg-main {
      height: 100%;
      display: flex;
      position: relative;

      &::before {
        content: '';
        height: @header-height;
        width: 100%;
        position: absolute;
        left: 0;
        background-color: @primary-color;
        box-shadow: inset 0 -2px 0 0 @blue-box-shadow;
        -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
        z-index: 2;
      }

      &::after {
        content: '';
        height: @header-height;
        width: 100%;
        position: absolute;
        left: 0;
        opacity: 0.5;
        background-color: @primary-color;
        box-shadow: inset 0 -2px 0 0 @grey-box-shadow;
        -webkit-clip-path: polygon(0 0, 90% 0, 65% 100%, 0% 100%);
        clip-path: polygon(0 0, 90% 0, 65% 100%, 0% 100%);
        z-index: 1;
      }
    }

    .company-logo {
      padding: 0 70px 0 30px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      z-index: 3;

      @media screen and (max-width: (@screen-md-min - 1)) {
        padding: 0 50px 0 0;
        font-size: 14px;
      }

      @media screen and (max-width: 350px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .company-logo-link {
          > span {
            line-height: 1.5;
          }
        }

        > :last-child {
          margin-left: 10px;
        }
      }

      .company-logo-link {
        color: #fff;

        &:focus {
          text-decoration: none;
        }

        > :first-child {
          margin-right: 5px;
        }
      }
    }
  }
}

body {
  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    padding: 13px 25px;
    display: flex;
    align-items: center;

    .dropdown-menu-title {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      color: @text-primary-color;

      &:hover {
        color: #000;
      }
    }

    &:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: @bg-color-light;
    }
  }
}

.header-dropdown + div {
  .ant-dropdown-menu {
    a {
      font-size: 14px;
      font-weight: 500;
    }

    .btn-default {
      border: none;
      margin: 0;
      padding-left: 0;
      font-size: 14px;
      font-weight: 500;
      box-shadow: none;
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-item:hover a,
    .ant-dropdown-menu-item:hover .btn-default,
    .ant-dropdown-menu-submenu-title:hover a,
    .ant-dropdown-menu-submenu-title:hover .btn-default {
      background-color: @bg-color-light;
      color: @text-accent-color;
    }
  }
}
