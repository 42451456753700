.layout .sider {
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.02), inset -2px 0 0 0 #ecedf2;
  background-color: #fff;
}
.layout .sider .trigger {
  padding: 30px;
  font-size: 16px;
}
.layout .content {
  background-color: #cfe2f9;
  min-height: calc(100vh - 64px);
}
.layout .nav .ant-menu-item {
  width: calc(99%);
  height: 60px;
  margin-top: 0;
  margin-bottom: 0 !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.layout .nav .ant-menu-item::after {
  border-color: #0084ff;
  right: auto;
  left: 0;
}
.layout .nav .ant-menu-item .nav-title {
  font-size: 16px;
  font-weight: 500;
  color: #9da6c0;
}
.layout .nav .ant-menu-item span {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: #9da6c0;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: middle;
}
.layout .nav .ant-menu-item .anticon {
  font-size: 16px;
  vertical-align: middle;
}
.layout .nav .ant-menu-item.ant-menu-item-selected {
  background-color: #f4f7fc;
}
.layout .nav .ant-menu-item.ant-menu-item-selected .icon svg {
  fill: #0084ff;
}
.layout .nav .ant-menu-item.ant-menu-item-selected.ant-menu-item::after {
  border-color: #0084ff;
  -webkit-transform: none;
          transform: none;
  opacity: 1;
  right: auto;
  left: 0;
}
.layout .nav.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.layout .header {
  background-color: #fff;
  padding-left: 0;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .layout .header .shapes {
    width: 100%;
    top: 0;
    position: absolute;
  }
  .layout .header .shapes .shape-1 {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
  }
  .layout .header .shapes .shape-1::before {
    content: '';
    position: absolute;
    left: -62px;
    top: 0;
    width: 0;
    height: 0;
    border: 18px solid transparent;
    border-top-color: #0084ff;
    opacity: 0.5;
    z-index: 4;
  }
  .layout .header .shapes .shape-1::after {
    content: '';
    position: absolute;
    left: -62px;
    top: 0;
    width: 0;
    height: 0;
    border: 62px solid transparent;
    border-top-color: #fff;
    z-index: 3;
  }
}
.layout .header .btn-default.inactive {
  opacity: 0.5;
  pointer-events: none;
}
.layout .header::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: transparent;
  box-shadow: 0 5px 15px 0 black;
  z-index: 30;
}
.layout .header .header-first-section {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .layout .header .header-first-section {
    flex-basis: 100%;
  }
}
.layout .header .header-first-section .icon.trigger {
  width: 80px;
}
@media screen and (max-width: 576px) {
  .layout .header .header-first-section .icon.trigger {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0084ff;
  }
  .layout .header .header-first-section .icon.trigger > svg {
    fill: #fff;
  }
}
.layout .header .header-first-section .header-search-area {
  padding-right: calc(13px);
}
.layout .header .header-second-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border-top: none;
  border-bottom: none;
  padding-right: 25px;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .layout .header .header-second-section {
    padding-left: 7px;
    padding-right: 7px;
    border: none;
  }
}
.layout .header .header-second-section .header__user-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  margin-right: 10px;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .layout .header .header-second-section .header__user-name {
    display: none;
  }
}
.layout .header .header-second-section .icon img {
  padding: 4px 0;
}
.layout .header .header-last-section:not(:empty) {
  padding-left: 15px;
  padding-right: 10px;
  border-left: 2px solid #ecedf2;
}
@media screen and (max-width: 576px) {
  .layout .header .header-last-section:not(:empty) {
    padding-left: 5px;
    padding-right: 0;
  }
}
.layout .header .company-bg-main {
  height: 100%;
  display: flex;
  position: relative;
}
.layout .header .company-bg-main::before {
  content: '';
  height: 64px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: #0084ff;
  box-shadow: inset 0 -2px 0 0 #0079ea;
  -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
  z-index: 2;
}
.layout .header .company-bg-main::after {
  content: '';
  height: 64px;
  width: 100%;
  position: absolute;
  left: 0;
  opacity: 0.5;
  background-color: #0084ff;
  box-shadow: inset 0 -2px 0 0 #ecedf2;
  -webkit-clip-path: polygon(0 0, 90% 0, 65% 100%, 0% 100%);
  clip-path: polygon(0 0, 90% 0, 65% 100%, 0% 100%);
  z-index: 1;
}
.layout .header .company-logo {
  padding: 0 70px 0 30px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  z-index: 3;
}
@media screen and (max-width: 767px) {
  .layout .header .company-logo {
    padding: 0 50px 0 0;
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  .layout .header .company-logo {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .layout .header .company-logo .company-logo-link > span {
    line-height: 1.5;
  }
  .layout .header .company-logo > :last-child {
    margin-left: 10px;
  }
}
.layout .header .company-logo .company-logo-link {
  color: #fff;
}
.layout .header .company-logo .company-logo-link:focus {
  text-decoration: none;
}
.layout .header .company-logo .company-logo-link > :first-child {
  margin-right: 5px;
}
body .ant-dropdown-menu {
  padding: 0;
}
body .ant-dropdown-menu-item {
  padding: 13px 25px;
  display: flex;
  align-items: center;
}
body .ant-dropdown-menu-item .dropdown-menu-title {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #9da6c0;
}
body .ant-dropdown-menu-item .dropdown-menu-title:hover {
  color: #000;
}
body .ant-dropdown-menu-item:hover,
body .ant-dropdown-menu-item .ant-dropdown-menu-submenu-title:hover {
  background-color: #f4f7fc;
}
.header-dropdown + div .ant-dropdown-menu a {
  font-size: 14px;
  font-weight: 500;
}
.header-dropdown + div .ant-dropdown-menu .btn-default {
  border: none;
  margin: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
}
.header-dropdown + div .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.header-dropdown + div .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.header-dropdown + div .ant-dropdown-menu .ant-dropdown-menu-item:hover a,
.header-dropdown + div .ant-dropdown-menu .ant-dropdown-menu-item:hover .btn-default,
.header-dropdown + div .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover a,
.header-dropdown + div .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover .btn-default {
  background-color: #f4f7fc;
  color: #2d3039;
}

.back-to-origin {
  z-index: 100;
}
.back-to-origin .ant-drawer-title {
  color: #2d3039;
}
.back-to-origin .ant-drawer-body {
  padding: 0;
}
.back-to-origin .ant-card-meta {
  padding-bottom: 10px;
}
.back-to-origin .ant-radio-wrapper {
  padding-bottom: 10px;
}
.back-to-origin .ant-btn {
  margin-top: 10px;
}
.back-to-origin .ant-typography,
.back-to-origin .ant-card-meta-title,
.back-to-origin .ant-card-meta-detail {
  color: #808d93;
}

#page-spinner {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 100000;
  position: fixed;
}
.spinner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spinner-wrapper .spinner-title {
  color: #2d3039;
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
}

.forbidden-root {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex: auto;
}
.forbidden-root .left-side {
  flex: 1 1;
  display: flex;
  overflow: hidden;
  background: #fff;
}
.forbidden-root .left-side .shapes {
  position: relative;
}
.forbidden-root .left-side .shapes .shape-1 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-left-color: #d3696b;
  position: absolute;
  top: 228px;
  left: 0;
}
.forbidden-root .left-side .shapes .shape-2 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-bottom-color: #bed738;
  position: absolute;
  bottom: 0;
  left: 0;
}
.forbidden-root .left-side .shapes .shape-3 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-bottom-color: #f1f4f9;
  position: absolute;
  bottom: 87.5px;
  left: 87.5px;
}
.forbidden-root .left-side .shapes .shape-3::after {
  content: '';
  position: absolute;
  left: -87.5px;
  top: 87.5px;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-top-color: #f1f4f9;
}
.forbidden-root .left-side .shapes .shape-4 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-bottom-color: #d3696b;
  position: absolute;
  bottom: 175px;
  left: calc(50vw - 110px);
}
.forbidden-root .left-side .shapes .shape-4::after {
  content: '';
  position: absolute;
  left: -87.5px;
  top: 87.5px;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-top-color: #d3696b;
}
.forbidden-root .left-side .shapes .shape-5 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-top-color: #bed738;
  position: absolute;
  top: 0;
  left: calc(50vw - 80px);
}
.forbidden-root .left-side .content {
  display: flex;
  padding-top: 135px;
  padding-bottom: 225px;
  flex: 1 1;
}
.forbidden-root .left-side .content .main-part {
  margin: 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.forbidden-root .left-side .content .main-part .label {
  font-size: 20px;
  z-index: 1;
  padding-bottom: 10px;
}
.forbidden-root .left-side .content .main-part .label :first-child {
  font-weight: 600;
  color: #acc437;
}
.forbidden-root .left-side .content .main-part .label :last-child {
  font-weight: 100;
  color: #9da6c0;
}
.forbidden-root .left-side .content .main-part .message .title {
  font-size: 72px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.63;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 40px;
}
.forbidden-root .left-side .content .main-part .message .text {
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 35px;
}
.forbidden-root .left-side .content .main-part .message .error {
  font-size: 18px;
  font-weight: 500;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 35px;
}
.forbidden-root .left-side .content .main-part .message .error :last-child {
  color: #0084ff;
}
.forbidden-root .left-side .content .main-part .forbidden-button {
  position: relative;
  z-index: 1;
  width: 75%;
  background-color: #0084ff;
  border-color: #0084ff;
  border-radius: 4px;
  min-height: 48px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forbidden-root .left-side .content .main-part .forbidden-button a {
  display: flex;
  min-height: 46px;
  width: 100%;
  padding-left: 44px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.forbidden-root .left-side .content .main-part .forbidden-button i {
  position: absolute;
  right: 27px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.forbidden-root .right-side {
  flex: 1 1;
  display: flex;
  background-image: url(/static/media/403-image.5e7183dc.jpg);
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
}
@media (max-width: 992px) {
  .forbidden-root .left-side .shapes .shape-4 {
    display: none;
  }
  .forbidden-root .left-side .content .main-part .forbidden-button {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .forbidden-root .left-side .shapes .shape-5 {
    left: calc(100vw - 80px);
  }
  .forbidden-root .right-side {
    display: none;
  }
}
@media (max-width: 600px) {
  .forbidden-root .left-side .shapes .shape-1,
  .forbidden-root .left-side .shapes .shape-5 {
    display: none;
  }
  .forbidden-root .left-side .content .main-part {
    width: 80%;
  }
}
@media (max-height: 840px) {
  .forbidden-root .left-side .shapes .shape-1 {
    display: none;
  }
  .forbidden-root .left-side .content {
    padding-top: 20px;
  }
}
@media (max-height: 667px) {
  .forbidden-root .left-side .content {
    padding-top: 10px;
    padding-bottom: 100px;
  }
  .forbidden-root .left-side .content .main-part .message .title {
    padding-bottom: 20px;
  }
  .forbidden-root .left-side .content .main-part .message .text {
    padding-bottom: 10px;
  }
  .forbidden-root .left-side .content .main-part .message .error {
    padding-bottom: 10px;
  }
}
@media screen and (max-height: 667px) and (orientation: landscape) {
  .forbidden-root {
    height: auto;
  }
  .forbidden-root .left-side .shapes .shape-1,
  .forbidden-root .left-side .shapes .shape-2,
  .forbidden-root .left-side .shapes .shape-3,
  .forbidden-root .left-side .shapes .shape-4,
  .forbidden-root .left-side .shapes .shape-5 {
    display: none;
  }
  .forbidden-root .left-side .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .forbidden-root .left-side .content .main-part {
    justify-content: flex-start;
    overflow-y: auto;
  }
  .forbidden-root .left-side .content .main-part .label {
    padding-bottom: 20px;
  }
  .forbidden-root .left-side .content .main-part .message .title {
    padding-bottom: 30px;
  }
  .forbidden-root .left-side .content .main-part .message .text {
    padding-bottom: 20px;
  }
  .forbidden-root .left-side .content .main-part .message .error {
    padding-bottom: 20px;
  }
  .forbidden-root .right-side {
    display: none;
  }
}

.server-error-root {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex: auto;
}
.server-error-root .left-side {
  flex: 1 1;
  display: flex;
  overflow: hidden;
  background: #fff;
}
.server-error-root .left-side .shapes {
  position: relative;
}
.server-error-root .left-side .shapes .shape-1 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-left-color: #d3696b;
  position: absolute;
  top: 228px;
  left: 0;
}
.server-error-root .left-side .shapes .shape-2 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-bottom-color: #bed738;
  position: absolute;
  bottom: 0;
  left: 0;
}
.server-error-root .left-side .shapes .shape-3 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-bottom-color: #f1f4f9;
  position: absolute;
  bottom: 87.5px;
  left: 87.5px;
}
.server-error-root .left-side .shapes .shape-3::after {
  content: '';
  position: absolute;
  left: -87.5px;
  top: 87.5px;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-top-color: #f1f4f9;
}
.server-error-root .left-side .shapes .shape-4 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-bottom-color: #d3696b;
  position: absolute;
  bottom: 175px;
  left: calc(50vw - 110px);
}
.server-error-root .left-side .shapes .shape-4::after {
  content: '';
  position: absolute;
  left: -87.5px;
  top: 87.5px;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-top-color: #d3696b;
}
.server-error-root .left-side .shapes .shape-5 {
  display: block;
  width: 0;
  height: 0;
  border: 87.5px solid transparent;
  border-top-color: #bed738;
  position: absolute;
  top: 0;
  left: calc(50vw - 80px);
}
.server-error-root .left-side .content {
  display: flex;
  padding-top: 135px;
  padding-bottom: 225px;
  flex: 1 1;
}
.server-error-root .left-side .content .main-part {
  margin: 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.server-error-root .left-side .content .main-part .label {
  font-size: 20px;
  z-index: 1;
  padding-bottom: 10px;
}
.server-error-root .left-side .content .main-part .label :first-child {
  font-weight: 600;
  color: #acc437;
}
.server-error-root .left-side .content .main-part .label :last-child {
  font-weight: 100;
  color: #9da6c0;
}
.server-error-root .left-side .content .main-part .message .title {
  font-size: 72px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.63;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 40px;
}
.server-error-root .left-side .content .main-part .message .text {
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 35px;
}
.server-error-root .left-side .content .main-part .message .error {
  font-size: 18px;
  font-weight: 500;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 35px;
}
.server-error-root .left-side .content .main-part .message .error :last-child {
  color: #0084ff;
}
.server-error-root .left-side .content .main-part .server-error-button {
  position: relative;
  z-index: 1;
  width: 75%;
  background-color: #0084ff;
  border-color: #0084ff;
  border-radius: 4px;
  min-height: 48px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.server-error-root .left-side .content .main-part .server-error-button a {
  display: flex;
  min-height: 46px;
  width: 100%;
  padding-left: 44px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.server-error-root .left-side .content .main-part .server-error-button i {
  position: absolute;
  right: 27px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.server-error-root .right-side {
  flex: 1 1;
  display: flex;
  background-image: url(/static/media/500-image.5e7183dc.jpg);
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
}
@media (max-width: 992px) {
  .server-error-root .left-side .shapes .shape-4 {
    display: none;
  }
  .server-error-root .left-side .content .main-part .server-error-button {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .server-error-root .left-side .shapes .shape-5 {
    left: calc(100vw - 80px);
  }
  .server-error-root .right-side {
    display: none;
  }
}
@media (max-width: 600px) {
  .server-error-root .left-side .shapes .shape-1,
  .server-error-root .left-side .shapes .shape-5 {
    display: none;
  }
  .server-error-root .left-side .content .main-part {
    width: 80%;
  }
}
@media (max-height: 840px) {
  .server-error-root .left-side .shapes .shape-1 {
    display: none;
  }
  .server-error-root .left-side .content {
    padding-top: 20px;
  }
}
@media (max-height: 667px) {
  .server-error-root .left-side .content {
    padding-top: 10px;
    padding-bottom: 100px;
  }
  .server-error-root .left-side .content .main-part .message .title {
    padding-bottom: 20px;
  }
  .server-error-root .left-side .content .main-part .message .text {
    padding-bottom: 10px;
  }
  .server-error-root .left-side .content .main-part .message .error {
    padding-bottom: 10px;
  }
}
@media screen and (max-height: 667px) and (orientation: landscape) {
  .server-error-root {
    height: auto;
  }
  .server-error-root .left-side .shapes .shape-1,
  .server-error-root .left-side .shapes .shape-2,
  .server-error-root .left-side .shapes .shape-3,
  .server-error-root .left-side .shapes .shape-4,
  .server-error-root .left-side .shapes .shape-5 {
    display: none;
  }
  .server-error-root .left-side .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .server-error-root .left-side .content .main-part {
    justify-content: flex-start;
    overflow-y: auto;
  }
  .server-error-root .left-side .content .main-part .label {
    padding-bottom: 20px;
  }
  .server-error-root .left-side .content .main-part .message .title {
    padding-bottom: 30px;
  }
  .server-error-root .left-side .content .main-part .message .text {
    padding-bottom: 20px;
  }
  .server-error-root .left-side .content .main-part .message .error {
    padding-bottom: 20px;
  }
  .server-error-root .right-side {
    display: none;
  }
}

/*  link color blue */
/* success state color */
/* warning state color (red) */
/* error state color (red) */
/* heading text color */
/* major border radius */

/* Import Ant Design styles by less entry */
/* variables to override above */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #2d3039;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body .layout-navbar-drawer .ant-drawer-body {
  padding-top: 40px;
  padding-right: 0;
  padding-left: 0;
}
#triage-trak-ui {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
#triage-trak-ui .text-field,
#triage-trak-ui .input {
  border: none;
  border-radius: 0;
  outline: none;
}
#triage-trak-ui .text-field input,
#triage-trak-ui .input input {
  border-top: none;
  outline: none;
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #0084ff;
}
#triage-trak-ui .text-field input:hover,
#triage-trak-ui .input input:hover,
#triage-trak-ui .text-field input:active,
#triage-trak-ui .input input:active,
#triage-trak-ui .text-field input:focus,
#triage-trak-ui .input input:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: #0084ff;
}
#triage-trak-ui .with-tip {
  position: relative;
}
#triage-trak-ui .with-tip::before {
  content: attr(data-tip);
  position: absolute;
  bottom: -60%;
  min-width: 50px;
  font-size: 10px;
  font-weight: 500;
  color: #9da6c0;
}
#triage-trak-ui .tip-success-color::before {
  color: #36c535;
}
#triage-trak-ui .tip-warning-color::before {
  color: #f85335;
}
#triage-trak-ui .tip-error-color::before {
  color: #f85335;
}
#triage-trak-ui .pl-3 {
  padding-left: 12px;
}
#triage-trak-ui .pr-3 {
  padding-right: 12px;
}
#triage-trak-ui .ant-menu-item .module-icon {
  color: #fff;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
}
.page {
  padding: 30px;
}
@media (max-width: 768px) {
  .page {
    padding: 20px;
  }
}
@media (max-width: 576px) {
  .page {
    padding: 5px;
  }
}
.icon > svg {
  fill: #b1b8cd;
  transition: 0.2s;
}
.bi-icon-color .ant-progress-inner {
  background-color: #fcc;
}
.bi-icon-color .ant-progress-bg {
  background-color: #f85334;
}
.bi-icon-color > svg {
  fill: #f85334;
}
.tt-icon-color .ant-progress-inner {
  background-color: #b3e0ff;
}
.tt-icon-color .ant-progress-bg {
  background-color: #0084ff;
}
.tt-icon-color > svg {
  fill: #0084ff;
}
.hover-pointer {
  transition: 0.4s;
}
.profile-avatar img {
  object-fit: cover;
}
.hover-pointer:hover,
.hover-pointer:focus {
  cursor: pointer;
  opacity: 0.75;
  transition: 0.4s;
}
.icon-hover {
  padding: 12px;
  border-radius: 50%;
  transition: 0.4s;
}
.icon-hover:hover,
.icon-hover:focus {
  background-color: #f4f7fc;
  transition: 0.4s;
}
.icon-hover.fill-primary:hover > svg,
.icon-hover.fill-primary:focus > svg {
  fill: #0084ff;
  transition: 0.2s;
}
.bi-module-icon {
  color: #f85334 !important;
}
.tt-module-icon {
  color: #0084ff !important;
}
.pg-module-icon {
  color: #acc437 !important;
  font-family: 'Montserrat', sans-serif;
}
.arrow-icon-right {
  -webkit-transform: rotate(0.5turn);
          transform: rotate(0.5turn);
}
.arrow-icon-up {
  -webkit-transform: rotate(0.25turn);
          transform: rotate(0.25turn);
}
.arrow-icon-down {
  -webkit-transform: rotate(-0.25turn);
          transform: rotate(-0.25turn);
}
.ant-btn.btn-default {
  border: none;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #485053;
}
.header-dropdown .ant-dropdown-menu a,
.custom-dropdown .ant-dropdown-menu a {
  font-size: 14px;
  font-weight: 500;
}
.header-dropdown .ant-dropdown-menu .btn-default,
.custom-dropdown .ant-dropdown-menu .btn-default {
  border: none;
  margin: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
}
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover a,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover a,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover .btn-default,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover .btn-default,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover a,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover a,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover .btn-default,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover .btn-default {
  background-color: #f4f7fc;
  color: #2d3039;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.visible-md {
  display: none;
}
@media screen and (max-width: 768px) {
  .visible-md {
    display: inline;
    display: initial;
  }
}
.visible-sm {
  display: none;
}
@media screen and (max-width: 576px) {
  .visible-sm {
    display: inline;
    display: initial;
  }
}
@media screen and (max-width: 576px) {
  .hidden-sm {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .hidden-md {
    display: none;
  }
}
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.google-places-autocomplete__suggestions-container {
  background-color: white;
  max-height: 200px;
  min-width: 300px;
  overflow-y: auto;
  padding: 0 10px;
  position: absolute;
  z-index: 5;
}
.error-notification-custom {
  white-space: break-spaces;
}

