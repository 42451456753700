/* Import Ant Design styles by less entry */
/* variables to override above */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #2d3039;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body .layout-navbar-drawer .ant-drawer-body {
  padding-top: 40px;
  padding-right: 0;
  padding-left: 0;
}
#triage-trak-ui {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
#triage-trak-ui .text-field,
#triage-trak-ui .input {
  border: none;
  border-radius: 0;
  outline: none;
}
#triage-trak-ui .text-field input,
#triage-trak-ui .input input {
  border-top: none;
  outline: none;
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #0084ff;
}
#triage-trak-ui .text-field input:hover,
#triage-trak-ui .input input:hover,
#triage-trak-ui .text-field input:active,
#triage-trak-ui .input input:active,
#triage-trak-ui .text-field input:focus,
#triage-trak-ui .input input:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: #0084ff;
}
#triage-trak-ui .with-tip {
  position: relative;
}
#triage-trak-ui .with-tip::before {
  content: attr(data-tip);
  position: absolute;
  bottom: -60%;
  min-width: 50px;
  font-size: 10px;
  font-weight: 500;
  color: #9da6c0;
}
#triage-trak-ui .tip-success-color::before {
  color: #36c535;
}
#triage-trak-ui .tip-warning-color::before {
  color: #f85335;
}
#triage-trak-ui .tip-error-color::before {
  color: #f85335;
}
#triage-trak-ui .pl-3 {
  padding-left: 12px;
}
#triage-trak-ui .pr-3 {
  padding-right: 12px;
}
#triage-trak-ui .ant-menu-item .module-icon {
  color: #fff;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
}
.page {
  padding: 30px;
}
@media (max-width: 768px) {
  .page {
    padding: 20px;
  }
}
@media (max-width: 576px) {
  .page {
    padding: 5px;
  }
}
.icon > svg {
  fill: #b1b8cd;
  transition: 0.2s;
}
.bi-icon-color .ant-progress-inner {
  background-color: #fcc;
}
.bi-icon-color .ant-progress-bg {
  background-color: #f85334;
}
.bi-icon-color > svg {
  fill: #f85334;
}
.tt-icon-color .ant-progress-inner {
  background-color: #b3e0ff;
}
.tt-icon-color .ant-progress-bg {
  background-color: #0084ff;
}
.tt-icon-color > svg {
  fill: #0084ff;
}
.hover-pointer {
  transition: 0.4s;
}
.profile-avatar img {
  object-fit: cover;
}
.hover-pointer:hover,
.hover-pointer:focus {
  cursor: pointer;
  opacity: 0.75;
  transition: 0.4s;
}
.icon-hover {
  padding: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: 0.4s;
}
.icon-hover:hover,
.icon-hover:focus {
  background-color: #f4f7fc;
  transition: 0.4s;
}
.icon-hover.fill-primary:hover > svg,
.icon-hover.fill-primary:focus > svg {
  fill: #0084ff;
  transition: 0.2s;
}
.bi-module-icon {
  color: #f85334 !important;
}
.tt-module-icon {
  color: #0084ff !important;
}
.pg-module-icon {
  color: #acc437 !important;
  font-family: 'Montserrat', sans-serif;
}
.arrow-icon-right {
  transform: rotate(0.5turn);
}
.arrow-icon-up {
  transform: rotate(0.25turn);
}
.arrow-icon-down {
  transform: rotate(-0.25turn);
}
.ant-btn.btn-default {
  border: none;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #485053;
}
.header-dropdown .ant-dropdown-menu a,
.custom-dropdown .ant-dropdown-menu a {
  font-size: 14px;
  font-weight: 500;
}
.header-dropdown .ant-dropdown-menu .btn-default,
.custom-dropdown .ant-dropdown-menu .btn-default {
  border: none;
  margin: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
}
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover a,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover a,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover .btn-default,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover .btn-default,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover a,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover a,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover .btn-default,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover .btn-default {
  background-color: #f4f7fc;
  color: #2d3039;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.visible-md {
  display: none;
}
@media screen and (max-width: 768px) {
  .visible-md {
    display: initial;
  }
}
.visible-sm {
  display: none;
}
@media screen and (max-width: 576px) {
  .visible-sm {
    display: initial;
  }
}
@media screen and (max-width: 576px) {
  .hidden-sm {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .hidden-md {
    display: none;
  }
}
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.google-places-autocomplete__suggestions-container {
  background-color: white;
  max-height: 200px;
  min-width: 300px;
  overflow-y: auto;
  padding: 0 10px;
  position: absolute;
  z-index: 5;
}
.error-notification-custom {
  white-space: break-spaces;
}
